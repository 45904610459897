<template>
  <v-container fluid>
    <V-row>
      <v-col md="12">
        <SettingItem
          @loadData="loadData"
          collection="categories"
          :items="categories"
          :itemHeader="categoryItemHeader"
        />
      </v-col>
    </V-row>
  </v-container>
</template>

<script>
import SettingItem from "./SettingItem/index";
import ApiService from "@/services/api.service";
import { mapActions, mapGetters } from "vuex";

export default {
  components: {
    SettingItem,
  },
  data() {
    return {
      categories: [],
      categories1: [],
      categoryItemHeader: [
        { text: "id", value: "id" },
        { text: "sequence", value: "sequence" },
        { text: "Title", value: "english_title" },
        { text: "Description", value: "enDesc" },
        { text: "Title", value: "arabic_title" },
        { text: "Description", value: "arDesc" },
        { text: "parent", value: "parent_category.english_title" },
        { text: "published Book", value: "pNum" },
        { text: "Avatar", value: "image" },
        { text: "Actions", value: "actions", sortable: false },
      ],
    };
  },
  methods: {
    async loadData(filter) {
      try {
        this.loading = true;
        this.fetchData(filter);
        this.loading = false;
      } catch (error) {
        console.log(error);
      }
    },

    async fetchData(filter) {
      try {
        console.log(filter);
        this.categories = [];
        let url =
          "items/categories?fields=products.product_id.status,child_categories.id,id,arabic_title,english_title,arabic_description,english_description,background_color,sequence,parent_category.english_title,parent_category.id,category_image.id,category_image.data.full_url,component_type_id.title";
        if (filter) {
          url = url + `&filter[parent_category]=${filter}`;
        }
        const data = await ApiService.get(url);
        let categories = data.data.data;
        categories.forEach((element) => {
          if (element.child_categories.length == 0) {
            if (element.parent_category && element.parent_category.id == 103) {
              this.categories.push(element);
            }
          }
          let num = 0;
          element.products.forEach((element1) => {
            if (
              element1.product_id &&
              element1.product_id.status == "published"
            ) {
              num++;
            }
          });
          element.pNum = num;
        });
      } catch (error) {
        console.log(error);
      }
    },
  },
  mounted() {
    this.fetchData(null);
  },
};
</script>

<style></style>
